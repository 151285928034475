import * as React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Intro from '../components/organisms/intro/Intro'
import { Helmet } from 'react-helmet'

const SignUp = () => {
  return (
    <>
      <Helmet>
        <title>In the Press | Muddy Stilettos</title>
      </Helmet>
      <Header />
      <Intro>
        <h1>In the Press</h1>
      </Intro>
      <Footer />
    </>
  )
}

export default SignUp
